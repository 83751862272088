import { getLocalStorageItem, setLocalStorageItem } from "./helperFunctions";

const DEFAULT_STORE_KEY = "DEFAULT_STORE_KEY";
const DEFAULT_QUEUE_KEY = "DEFAULT_QUEUE_KEY";
const DEFAULT_STATUS_KEY = "DEFAULT_STATUS_KEY";
function getDefaultStatus() {
  return getDefaultValue(DEFAULT_STATUS_KEY);
}
function setDefaultStatus(status) {
  setLocalStorageItem(DEFAULT_STATUS_KEY, { value: status });
}
function setDefaultQueueId(queueId) {
  setLocalStorageItem(DEFAULT_QUEUE_KEY, { value: queueId });
}
function getDefaultQueueId() {
  return getDefaultValue(DEFAULT_QUEUE_KEY);
}
function getDefaultValue(key) {
  const item = getLocalStorageItem(key);
  if (item) {
    return item.value;
  }
  return "";
}
function setDefaultStoreId(storeId) {
  setLocalStorageItem(DEFAULT_STORE_KEY, { storeId });
}
function getDefaultStoreId() {
  const item = getLocalStorageItem(DEFAULT_STORE_KEY);
  if (item) {
    return item.storeId;
  }
  return null;
}

export default {
  setDefaultStoreId,
  getDefaultStoreId,
  setDefaultQueueId,
  getDefaultQueueId,
  getDefaultStatus,
  setDefaultStatus,
};
