import React from "react";
import { AmplifyProvider, withAuthenticator } from "@aws-amplify/ui-react";

function AuthenticateWrapper({ components, user, signOut }) {
  return (
    <AmplifyProvider>
      <div>
        <div className="container">{components}</div>
      </div>
    </AmplifyProvider>
  );
}

export default withAuthenticator(AuthenticateWrapper, {
  config: {},
});
