import { INPUT_TYPE } from "../constants";
export const TimeRelatedFields = ["createdAt", "lastModifiedAt"];
export const CASE_STATUS_ENUM = [
  { name: "Open", value: "Open" },
  { name: "Order Recieved", value: "OrderRecieved" },
  { name: "Notification Sent", value: "NotificationSent" },
  { name: "Closed", value: "Closed" },
];

export const CaseDetailConfig = [
  {
    label: "Title",
    fieldName: "title",
    inputType: INPUT_TYPE.TEXT,
    disable: false,
    required: true,
  },
  {
    label: "Description",
    fieldName: "description",
    inputType: INPUT_TYPE.TEXT_AREA,
    disable: false,
    required: true,
  },
  {
    label: "Status",
    fieldName: "caseStatus",
    inputType: INPUT_TYPE.SELECT,
    disable: false,
    enumValues: CASE_STATUS_ENUM,
  },
  {
    label: "Customer Email",
    fieldName: "customerEmail",
    inputType: INPUT_TYPE.EMAIL,
    disable: false,
    required: true,
  },
  {
    label: "Queue",
    fieldName: "queueId",
    inputType: INPUT_TYPE.SELECT, // add enum
    disable: false,
  },
  {
    label: "Customer Firstname",
    fieldName: "customerFirstname",
    inputType: INPUT_TYPE.TEXT,
    disable: false,
  },
  {
    label: "Customer Lastname",
    fieldName: "customerLastname",
    inputType: INPUT_TYPE.TEXT,
    disable: false,
  },
  {
    label: "Last Modified By",
    fieldName: "lastModifiedBy",
    inputType: INPUT_TYPE.TEXT,
    hideWhenEdit: true,
    disable: true,
  },
  {
    label: "Last Modified Date",
    fieldName: "lastModifiedAt",
    inputType: INPUT_TYPE.DATE,
    disable: true,
    hideWhenEdit: true,
  },
  {
    label: "Created Date",
    fieldName: "createdAt",
    inputType: INPUT_TYPE.DATE,
    disable: true,
    hideWhenEdit: true,
  },
];
