import React, { useCallback, useContext, useEffect, useState } from "react";
import { Accordion, Badge, Button, Card, Form, Tab, Tabs } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { EditableModal } from "../components/editableModal";
import { INPUT_TYPE } from "../constants";
import AppContext from "../context/app-context";
import { CaseDetailConfig } from "../inputConfigs/caseConfig";
import userProfileService from "../services/userProfileService";
import { isValidEmail } from "../utils/helperFunctions";
import EmailEditorWrapper from "../components/EmailEditorWrapper";
import EmailDetails from "../widget/EmailDetails";
import TimeWidget from "../widget/TimeWidget";
import localMetadataDB from "../storage/localMetadataDB";
import TitleWithNotificationDot from "../widget/TitleWithNotificationDot";

export const rightAlignStyle = {
  margin: "5px",
  display: "flex",
  justifyContent: "flex-end",
};

export const UpdateCaseOperations = {
  SendConfirmationEmail: "SendConfirmationEmail",
};

const caseDetailTabs = {
  Activity: "Activities",
  Messages: "Messages",
  Emails: "Emails",
  Notes: "Notes",
};
export default function AgentCaseDetaildiv() {
  const {
    currentProfile,
    setAppRefresh,
    setLoading = () => {},
    queuesSelectsOptions,
    onCaseChange = () => {},
  } = useContext(AppContext);
  const {
    agentProfile: { defaultQueueId, storeId, defaultStatus = "" } = {},
    queueList: { queues = [] } = {},
    storeDetails: { billingPlan, storeName, myshopifyDomain = "" } = {},
    storeDetails: storeInfo,
  } = currentProfile || {};
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get("tab") || caseDetailTabs.Activity;
  const { caseId } = useParams();
  const [refreshCase, setRefreshCase] = useState(0);
  const [caseDetail, setCaseDetails] = useState({});
  const [auditTrailEvents, setAuditTrailEvents] = useState([]);
  const [messageEvents, setMessageEvents] = useState([]);
  const [noteEvents, setNoteEvents] = useState([]);
  const [emailEvents, setEmailEvents] = useState([]);
  const [newlyAddedMessage, setNewlyAddedMessage] = useState([]);
  const [newlyAddedNote, setNewlyAddedNote] = useState([]);
  const [newlyAddedEmail, setNewlyAddedEmail] = useState([]);
  const [localMetadata, setLocalMetadata] = useState({});

  async function updateLocalMetaData(item) {
    await localMetadataDB.putItemTocaseMetaDataTable({ ...localMetadata, caseId, ...item });
  }

  async function getCaseAdditionalDetails({ caseId, activityExcludeKey, messageExcludeKey, noteExcludeKey }) {
    setLoading(true);
    userProfileService.getCase({
      caseId,
      activityExcludeKey,
      messageExcludeKey,
      noteExcludeKey,
      callback: ({ caseDetail: details, messageAsset, noteAsset, auditTrailAsset, error, emailAsset }) => {
        setLoading(false);
        if (error) {
          toast.error(error);
          return;
        }

        async function getOrUpdateModifiedAt() {
          try {
            const local = await localMetadataDB.getcaseMetaDataTableById(caseId);
            if (local.lastModifiedAt !== details.lastModifiedAt) {
              localMetadataDB.putItemTocaseMetaDataTable({ caseId, lastModifiedAt: details.lastModifiedAt });
              setLocalMetadata({ caseId, lastModifiedAt: details.lastModifiedAt });
            } else {
              setLocalMetadata(local);
            }
          } catch (e) {
            console.error("Unable to get local datas");
          }
        }
        getOrUpdateModifiedAt();
        setNewlyAddedEmail([]);
        if (emailAsset) setEmailEvents([emailAsset]);
        setCaseDetails(details);
        onCaseChange(details);
        if (activityExcludeKey) {
          toast.success("Successuflly loaded more activities.");
          setAuditTrailEvents((prev) => [...prev, auditTrailAsset]);
        } else if (auditTrailAsset) setAuditTrailEvents((prev) => [auditTrailAsset]);
        if (messageExcludeKey) {
          toast.success("Successuflly loaded more messages.");
          setMessageEvents((prev) => [...prev, messageAsset]);
        } else if (messageAsset) setMessageEvents((prev) => [messageAsset]);
        if (noteExcludeKey) {
          toast.success("Successuflly loaded more notes.");
          setNoteEvents((prev) => [...prev, noteAsset]);
        } else if (noteAsset) setNoteEvents((prev) => [noteAsset]);
      },
    });
  }

  async function updateCaseDetails({ caseId, payload, newMessage, newNote, operation }) {
    setLoading(true);
    userProfileService.updateCase({
      caseId,
      payload,
      newMessage,
      newNote,
      operation,
      callback: ({ error, caseDetail: details, messageAsset = {}, noteAsset = {} }) => {
        setLoading(false);
        if (error) {
          toast.error(error);
          return;
        }
        setCaseDetails((prev) => ({ ...prev, ...details }));
        onCaseChange(details);
        var updateLocal = { lastModifiedAt: details.lastModifiedAt };
        if (messageAsset.newMessage) {
          setNewlyAddedMessage((prev) => [messageAsset.newMessage, ...prev]);
          updateLocal = { ...updateLocal, lastCommentedAt: messageAsset.newMessage.createdAt };
          toast.success(`Succesfully added ${newMessage} as a message.`);
        } else if (noteAsset.newNote) {
          setNewlyAddedNote((prev) => [noteAsset.newNote, ...prev]);
          toast.success(`Succesfully added ${newNote} as a note.`);
        } else if (operation === UpdateCaseOperations.SendConfirmationEmail) {
          toast.success(`Confirmation email is sent to ${caseDetail.customerEmail}.`);
        } else {
          toast.success("Succesfully updated Case detail.");
        }
        updateLocalMetaData(updateLocal);
      },
    });
  }

  useEffect(() => {
    if (caseId && storeId) getCaseAdditionalDetails({ caseId });
  }, [caseId, storeId, refreshCase]);

  const addCaseAsset = ({ content, isMessage }) => {
    if (isMessage) {
      updateCaseDetails({ caseId, newMessage: content });
    } else {
      updateCaseDetails({ caseId, newNote: content });
    }
  };
  const { confimrationEmailSent, customerEmail, shopifyOrderId, description = "" } = caseDetail;

  let orderUrl = "";
  if (myshopifyDomain && shopifyOrderId) {
    const shopifyShopId = myshopifyDomain.replace(".myshopify.com", "");
    const orderId = shopifyOrderId.replace("gid://shopify/Order/", "");
    if (shopifyOrderId.length > 0 && orderId.length > 0) {
      orderUrl = `https://admin.shopify.com/store/${shopifyShopId}/orders/${orderId}`;
    }
  }
  const displayingEmailEvents = [
    ...newlyAddedEmail,
    ...emailEvents.flatMap((item) =>
      item.assetList.map((asset) => {
        return asset;
      })
    ),
  ];
  let defaultEmailKey = "";
  if (displayingEmailEvents.length > 0) {
    defaultEmailKey = displayingEmailEvents[0].createdAt;
  }
  const showEmailNewBadge =
    emailEvents.length > 0 &&
    emailEvents[0]?.assetList.length > 0 &&
    localMetadata.lastEmailedAt !== emailEvents[0]?.assetList[0].createdAt;

  useEffect(() => {
    if (activeTab === caseDetailTabs.Emails && emailEvents.length > 0 && emailEvents[0]?.assetList.length > 0) {
      const newLastEmailedAt = emailEvents[0]?.assetList[0]?.createdAt;
      if (newLastEmailedAt && newLastEmailedAt !== localMetadata.lastEmailedAt) {
        updateLocalMetaData({ lastEmailedAt: newLastEmailedAt });
        setLocalMetadata((prev) => ({ ...prev, lastEmailedAt: newLastEmailedAt }));
      }
    } else if (activeTab === caseDetailTabs.Messages) {
      if (caseDetail.lastCommentedAt && localMetadata.lastCommentedAt !== caseDetail.lastCommentedAt) {
        updateLocalMetaData({ lastCommentedAt: caseDetail.lastCommentedAt });
        setLocalMetadata((prev) => ({ ...prev, lastCommentedAt: caseDetail.lastCommentedAt }));
      }
    }
  }, [activeTab]);
  return (
    <div>
      <Card>
        <Card.Header>
          <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
            <h1>{caseDetail.title}</h1>
          </div>
        </Card.Header>
        <Card.Body>
          <div>
            <Card>
              <div>
                <div style={{ margin: "12px" }}>
                  <Form.Control
                    as="textarea"
                    rows={Math.max(description.split("\n").length, 2)}
                    value={caseDetail.description}
                    disabled={true}
                  />
                  {orderUrl && (
                    <a href={orderUrl} target="_blank">
                      View Order Details
                    </a>
                  )}
                  {!confimrationEmailSent && (
                    <div style={rightAlignStyle}>
                      <Button
                        disabled={confimrationEmailSent || !isValidEmail(customerEmail)}
                        onClick={() => {
                          updateCaseDetails({ caseId, operation: UpdateCaseOperations.SendConfirmationEmail });
                        }}
                      >
                        Send Confirmation Email
                      </Button>
                    </div>
                  )}
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {CaseDetailConfig.slice(2).map((att) => {
                    let displayValue = caseDetail[att.fieldName];
                    if (att.inputType === INPUT_TYPE.DATE) {
                      displayValue = new Date(displayValue).toLocaleString();
                    }
                    if (att.fieldName === "queueId") {
                      const queueDetail = queues.find((que) => que.queueId === displayValue);
                      displayValue = queueDetail?.queueName;
                    }
                    if (!displayValue) {
                      return;
                    }
                    return (
                      <div style={{ margin: "0 10px" }} key={att.fieldName}>
                        <Form.Group id={att.fieldName}>
                          <Form.Label>{att.label}</Form.Label>
                          <Form.Control type="text" readOnly value={displayValue} />
                        </Form.Group>
                      </div>
                    );
                  })}
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", margin: "10px 5px" }}>
                  {" "}
                  <Button onClick={() => setRefreshCase(Math.random())}>Refresh Details</Button>
                  <EditableModal
                    title={"Edit Case"}
                    currentValue={caseDetail}
                    configs={CaseDetailConfig.map((item) => {
                      if (item.fieldName === "queueId") {
                        return { ...item, enumValues: queuesSelectsOptions };
                      }
                      return item;
                    })}
                    actionLabel={"Update Case"}
                    actionOnClick={(data) => {
                      console.log(data);
                      updateCaseDetails({ caseId, payload: data });
                    }}
                  />
                </div>
              </div>
              <div style={{ marginTop: "15px" }}>
                <hr />
              </div>
              <Tabs
                variant="tabs"
                onSelect={(val) => {
                  setSearchParams({
                    tab: val,
                  });
                }}
                defaultActiveKey={activeTab}
                id="case-tabs"
              >
                <Tab eventKey={caseDetailTabs.Activity} title={caseDetailTabs.Activity}>
                  <AuditTrailComp
                    queues={queues}
                    storeInfo={storeInfo}
                    auditTrailEvents={auditTrailEvents}
                    loadMore={() => {
                      getCaseAdditionalDetails({
                        caseId,
                        activityExcludeKey: auditTrailEvents[auditTrailEvents.length - 1].LastEvaluatedKey,
                      });
                    }}
                  />
                </Tab>
                <Tab
                  eventKey={caseDetailTabs.Messages}
                  title={
                    <TitleWithNotificationDot
                      title={caseDetailTabs.Messages}
                      showNotification={localMetadata.lastCommentedAt !== caseDetail.lastCommentedAt}
                    />
                  }
                >
                  <CaseAssetComps
                    assetList={messageEvents}
                    isMessage={true}
                    newEvents={newlyAddedMessage}
                    addCaseAsset={addCaseAsset}
                    storeInfo={storeInfo}
                    loadMore={() => {
                      getCaseAdditionalDetails({
                        caseId,
                        messageExcludeKey: messageEvents[messageEvents.length - 1].LastEvaluatedKey,
                      });
                    }}
                  />
                </Tab>
                <Tab
                  eventKey={caseDetailTabs.Emails}
                  title={<TitleWithNotificationDot title={caseDetailTabs.Emails} showNotification={showEmailNewBadge} />}
                >
                  <EmailEditorWrapper
                    caseDetail={caseDetail}
                    setNewlyAddedEmail={setNewlyAddedEmail}
                    displayingEmailEvents={displayingEmailEvents}
                  />
                </Tab>
                <Tab eventKey={caseDetailTabs.Notes} title={caseDetailTabs.Notes}>
                  <CaseAssetComps
                    assetList={noteEvents}
                    isMessage={false}
                    newEvents={newlyAddedNote}
                    addCaseAsset={addCaseAsset}
                    storeInfo={storeInfo}
                    loadMore={() => {
                      getCaseAdditionalDetails({
                        caseId,
                        noteExcludeKey: noteEvents[noteEvents.length - 1].LastEvaluatedKey,
                      });
                    }}
                  />
                </Tab>
              </Tabs>
            </Card>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

function CaseAssetComps(props) {
  const { assetList = [], isMessage, addCaseAsset, storeInfo, loadMore, newEvents } = props;
  const [value, setValue] = useState("");
  return (
    <div>
      <div>
        <Form.Control
          label={isMessage ? "Send a message to the customer" : "Save an note on the case"}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          as="textarea"
          rows={3}
        />
        <div style={rightAlignStyle}>
          <Button
            onClick={() => {
              addCaseAsset({ content: value, isMessage });
              setValue("");
            }}
          >
            {isMessage ? "Add message" : "Add Note"}
          </Button>
        </div>
      </div>
      <div>
        {assetList.map(({ assetList: list = [] } = {}, index) => {
          let displayItems = list;
          if (index === 0) {
            displayItems = [...newEvents, ...list];
          }
          return displayItems.map((asset) => {
            const { note, message, createdAt, lastModifiedBy } = asset;

            return (
              <div key={createdAt} style={{ margin: "3px 0" }}>
                <b style={{ margin: "0 5px" }}>
                  <TimeWidget value={createdAt} />{" "}
                </b>
                <b style={{ margin: "0 5px" }}>{getLastModifiedBy(storeInfo, lastModifiedBy)}</b> Added
                <b style={{ margin: "0 5px" }}>{isMessage ? message : note}</b>
                {isMessage ? " as a message." : " as a note."}
              </div>
            );
          });
        })}
      </div>
      <div style={rightAlignStyle}>
        <Button disabled={!assetList[assetList.length - 1]?.LastEvaluatedKey} onClick={() => loadMore()}>
          Load more
        </Button>
      </div>
    </div>
  );
}

export function getLastModifiedBy(storeInfo, lastModifiedBy) {
  const isMerchant = lastModifiedBy === storeInfo.storeId;
  return isMerchant ? storeInfo.storeName : lastModifiedBy;
}

function AuditTrailComp(props) {
  const { assetList = [], auditTrailEvents, queues = [], storeInfo, activityLastEvaKeys, loadMore } = props;

  return (
    <div>
      {auditTrailEvents.map((lists) => {
        return lists.assetList.map((asset) => {
          let { modifiedBy, attributeId, oldValue, createdAt, newValue, createdAt_id } = asset;

          const isMerchant = modifiedBy === storeInfo?.storeId;

          if (attributeId === "queueId") {
            if (newValue) {
              const queueDetail = queues.find((que) => que.queueId === newValue);
              if (queueDetail) newValue = queueDetail.queueName;
            }
            if (oldValue) {
              const queueDetail = queues.find((que) => que.queueId === oldValue);
              if (queueDetail) oldValue = queueDetail.queueName;
            }
          }

          return (
            <div key={createdAt_id} style={{ margin: "3px 0" }}>
              <b style={{ margin: "0 5px" }}>
                <TimeWidget value={createdAt} />{" "}
              </b>
              :<b style={{ margin: "0 5px" }}>{isMerchant ? storeInfo.storeName : modifiedBy}</b>{" "}
              {oldValue ? "updated" : "added"}
              <b style={{ margin: "0 5px" }}>{attributeId}</b> {oldValue ? "from" : ""}
              <b style={{ margin: "0 5px" }}>{oldValue ? `"${oldValue}"` : ""}</b> {oldValue ? "to" : ""}
              <b style={{ margin: "0 5px" }}>"{newValue}"</b>
            </div>
          );
        });
      })}
      <div style={rightAlignStyle}>
        <Button disabled={!auditTrailEvents[auditTrailEvents.length - 1]?.LastEvaluatedKey} onClick={() => loadMore()}>
          Load more
        </Button>
      </div>
    </div>
  );
}
