import { Auth } from "aws-amplify";
import axios from "axios";
import { toast } from "react-toastify";
import { isUserLogined } from "../context/useAuth";
import { getUserJwtToken, handleAPICallError } from "../utils/helperFunctions";

const USER_SERVICE_URL = "https://profile.service.traveai.com";

const client = axios.create({ baseURL: USER_SERVICE_URL });

const API_PATH = {
  identity: "/identity",
  organization: "organization",
  profile: "profile",
};

async function getUserProfile({ callback }) {
  if (!(await isUserLogined())) {
    callback({});
    return;
  }
  const user = await Auth.currentAuthenticatedUser();

  const jwtToken = getUserJwtToken(user);
  client
    .get(`/get-profile`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: jwtToken,
      },
    })
    .then((res) => {
      //   console.log("Success:", res);
      const { data } = res;
      callback({ ...data });
    })
    .catch((err) => {
      handleAPICallError({ callback, err });
    });
}

async function getCases({ callback, storeId, queueId, queryStatus, ExclusiveStartKey }) {
  if (!(await isUserLogined())) {
    callback({});
    return;
  }
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = getUserJwtToken(user);
  client
    .post(
      `/get-cases`,
      { storeId, queueId, queryStatus, ExclusiveStartKey },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: jwtToken,
        },
      }
    )
    .then((res) => {
      const { data } = res;
      callback({ ...data });
    })
    .catch((err) => {
      handleAPICallError({ callback, err });
    });
}
async function getCase({ callback, caseId, activityExcludeKey, messageExcludeKey, noteExcludeKey }) {
  if (!(await isUserLogined())) {
    callback({});
    return;
  }
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = getUserJwtToken(user);
  client
    .post(
      `/get-case`,
      { caseId, activityExcludeKey, messageExcludeKey, noteExcludeKey },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: jwtToken,
        },
      }
    )
    .then((res) => {
      const { data } = res;
      callback({ ...data });
    })
    .catch((err) => {
      handleAPICallError({ callback, err });
    });
}

async function updateCase({ callback, caseId, payload, newMessage, newNote, operation, newEmail }) {
  if (!(await isUserLogined())) {
    callback({});
    return;
  }
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = getUserJwtToken(user);
  client
    .post(
      `/update-case`,
      { caseId, payload, newMessage, newNote, operation, newEmail },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: jwtToken,
        },
      }
    )
    .then((res) => {
      const { data } = res;
      callback({ ...data });
    })
    .catch((err) => {
      handleAPICallError({ callback, err });
    });
}

async function createCase({
  callback,
  title,
  description,
  caseStatus,
  customerFirstname,
  customerLastname,
  queueId,
  customerEmail,
  sendInvitationEmail,
  storeId,
}) {
  if (!(await isUserLogined())) {
    callback({});
    return;
  }
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = getUserJwtToken(user);
  client
    .post(
      `/create-case`,
      {
        title,
        description,
        caseStatus,
        customerFirstname,
        customerLastname,
        queueId,
        customerEmail,
        sendInvitationEmail,
        storeId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: jwtToken,
        },
      }
    )
    .then((res) => {
      const { data } = res;
      callback({ ...data });
    })
    .catch((err) => {
      handleAPICallError({ callback, err });
    });
}

async function updateStore({
  callback,
  storeId,
  newQueue,
  defaultQueueId,
  defaultStatus,
  confirmationEmailEnable,
  confirmationEmailTemplate,
}) {
  if (!(await isUserLogined())) {
    callback({});
    return;
  }
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = getUserJwtToken(user);
  client
    .post(
      `/update-store`,
      { storeId, newQueue, defaultQueueId, defaultStatus, confirmationEmailEnable, confirmationEmailTemplate },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: jwtToken,
        },
      }
    )
    .then((res) => {
      const { data } = res;
      callback({ ...data });
    })
    .catch((err) => {
      handleAPICallError({ callback, err });
    });
}

async function createAgent({ callback, storeId, emailAddress, firstname, lastname }) {
  if (!(await isUserLogined())) {
    callback({});
    return;
  }
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = getUserJwtToken(user);
  client
    .post(
      `/create-agent`,
      { storeId, emailAddress, firstname, lastname },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: jwtToken,
        },
      }
    )
    .then((res) => {
      const { data } = res;
      callback({ ...data });
    })
    .catch((err) => {
      handleAPICallError({ callback, err });
    });
}
export default {
  getUserProfile,
  getCases,
  getCase,
  createCase,
  updateCase,
  updateStore,
  createAgent,
};
