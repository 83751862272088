import React, { useCallback, useContext, useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";

import CustomTable from "../components/customTable";
import { INPUT_TYPE } from "../constants";
import AppContext from "../context/app-context";
import { CreateUserConfig, FREE_TRAIL, StoreUpdateConfig } from "../inputConfigs/storeConfig";
import { getFeatureConfigByPlan } from "../utils/planConfig";
import { EditableModal } from "../components/editableModal";
import userProfileService from "../services/userProfileService";
import { toast } from "react-toastify";
import { rightAlignStyle } from "./agentCaseDetailPage";

const displayHeadersForAgentList = [
  { label: "First Name", fieldName: "firstname" },
  { label: "Last Name", fieldName: "lastname" },
  { label: "Email Address", fieldName: "emailAddress" },
  { label: "Agent Profile", fieldName: "agentProfile" },
  //   { label: "Update Profile", fieldName: "updateProfile" },
];

export default function StoreDetailPage() {
  const {
    currentProfile = {},
    setAppRefresh,
    setLoading = () => {},
    queuesSelectsOptions,
    onCaseChange = () => {},
  } = useContext(AppContext);
  if (!currentProfile) {
    return;
  }
  const {
    agentProfile: { firstname: loginUserFirstName, emailAddress: loginEmail, agentProfile } = {},
    storeDetails = {},
    queueList: { queues = [], queues: [{ queueId, queueName }] = [] } = {},
    agentListResponse: { agents: agentList = [] } = {},
  } = currentProfile;
  const { homeUrl, billingPlan, createdAt, storeId, freeTrailEndInDays, myshopifyDomain } = storeDetails;
  const emailToCaseAddress = `team+${storeId}@travel-llm.com`;

  const displayingAgentList = agentList.map((item) => {
    const { emailAddress } = item;
    return displayHeadersForAgentList.map(({ fieldName }) => {
      if (fieldName === "updateProfile" && loginEmail === emailAddress) {
        return {
          value: "Update",
        };
      }
      return { value: item[fieldName] };
    });
  });
  let billingPlanMsg = (
    <div>
      <p>
        Your Store is currently on <span style={{ color: "green" }}> {billingPlan}! </span>Thanks for selecting Customer Link
        Hub!
      </p>
    </div>
  );
  if (billingPlan === FREE_TRAIL) {
    billingPlanMsg = (
      <div>
        <p>
          Your Store is currently on {billingPlan}!{" "}
          <span style={{ color: "red" }}>
            Your Free Trail is ending in {freeTrailEndInDays} {freeTrailEndInDays <= 1 ? "day" : "days"}. Please remember to
            select a plan in Shopify.
          </span>
        </p>
      </div>
    );
  }
  const featureFlags = getFeatureConfigByPlan(billingPlan);
  const maxQueueCount = featureFlags.maxQueueCount || 1;
  const maxUserCount = featureFlags.maxUserCount || 1;
  const isAdminUser = agentProfile === "Admin";

  async function updateStoreSetting(item) {
    setLoading(true);
    userProfileService.updateStore({
      ...item,
      storeId,
      callback: ({ error, ...data }) => {
        setLoading(false);
        if (error) {
          toast.error(error);
        }
        setAppRefresh(Math.random());
      },
    });
  }

  return (
    <Card>
      <Card.Header>
        <h1>{storeDetails.storeName}</h1>
      </Card.Header>
      <Card.Body>
        <section>
          {/* <h2>Store Details</h2> */}
          <div>
            <p>Welcome back {loginUserFirstName}! </p>
            {billingPlanMsg}
            <p>
              Plan include up to {maxQueueCount} queues. You currently have {queues.length}{" "}
              {queues.length === 1 ? " queue." : " queues."}
            </p>
            <p>
              Plan include up to {maxUserCount} users. You currently have {agentList.length}{" "}
              {agentList.length === 1 ? " user." : " users."}
            </p>
            <p>
              Email to case Address: <b>{emailToCaseAddress}</b>.{" "}
              <a
                href="https://medium.com/@CustomerLinkHub/revolutionize-customer-support-with-email-to-case-automation-8d5a5f82dcb9"
                target="_blank"
              >
                Learn more
              </a>
            </p>
            <p>
              Your Online Store Url:{" "}
              <a href={homeUrl} target="_blank">
                {homeUrl}
              </a>
            </p>
          </div>
          <h4>Fields can be edit on Shopify Admin console</h4>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {StoreUpdateConfig.map((att) => {
              let displayValue = storeDetails[att.fieldName];
              if (att.inputType === INPUT_TYPE.DATE) {
                displayValue = new Date(displayValue).toLocaleString();
              }
              if (att.inputType === INPUT_TYPE.BOOLEAN) {
                displayValue = displayValue ? "Yes" : "No";
              }

              if (att.fieldName === "defaultQueueId") {
                const queueDetail = queues.find((que) => que.queueId === displayValue);
                displayValue = queueDetail?.queueName;
              }
              return (
                <div style={{ margin: "0 10px" }} key={att.fieldName}>
                  <Form.Group id={att.fieldName}>
                    <Form.Label>{att.label}</Form.Label>
                    <Form.Control type="text" readOnly value={displayValue} />
                  </Form.Group>
                </div>
              );
            })}
          </div>
        </section>

        <section>
          <p>
            Notes that your current plan could have up to {maxQueueCount} queues. You currently have {queues.length}{" "}
            {queues.length === 1 ? " queue." : " queues."}
          </p>
          {isAdminUser && queues.length <= maxQueueCount && (
            <EditableModal
              title={"Create a New Queue"}
              currentValue={{}}
              configs={[
                {
                  label: "Queue Name",
                  fieldName: "newQueueName",
                  inputType: INPUT_TYPE.TEXT,
                  disable: false,
                  required: true,
                },
                {
                  label: "Queue Description",
                  fieldName: "newQueueDescription",
                  inputType: INPUT_TYPE.TEXT_AREA,
                  disable: false,
                },
              ]}
              actionLabel={"Create"}
              actionOnClick={(data) => {
                console.log(data);
                updateStoreSetting({
                  newQueue: data,
                });
              }}
            />
          )}
        </section>
        <section style={{ marginTop: "10px" }}>
          <h3>Store Members</h3>
          {isAdminUser && displayingAgentList.length <= maxUserCount && (
            <div style={rightAlignStyle}>
              <EditableModal
                title={"Add a New User"}
                currentValue={{}}
                configs={CreateUserConfig}
                actionLabel={"Create"}
                actionOnClick={(data) => {
                  setLoading(true);
                  userProfileService.createAgent({
                    ...data,
                    storeId,
                    callback: ({ error, ...data }) => {
                      setLoading(false);
                      if (error) {
                        toast.error(error);
                      }
                      setAppRefresh(Math.random());
                    },
                  });
                }}
              />
            </div>
          )}
          <CustomTable
            headers={displayHeadersForAgentList}
            rows={displayingAgentList}
            colCanSort={displayHeadersForAgentList.map((item) => false)}
          />
        </section>
      </Card.Body>
    </Card>
  );
}
