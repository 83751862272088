import { useState } from "react";
import { getTimeSinceDate } from "../utils/helperFunctions";

export default function TimeWidget({ value }) {
  const date = new Date(value);
  const [displayIndex, setDisplayIndex] = useState(0);

  const displayValue = displayIndex % 2 === 0 ? getTimeSinceDate(date) : date.toLocaleString();
  return (
    <span style={{ cursor: "pointer" }} onClick={() => setDisplayIndex((prev) => prev + 1)}>
      {displayValue}
    </span>
  );
}
