import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import SelectSearch from "react-select-search";

import { INPUT_TYPE } from "../constants";
import { isValidEmail, isValidNumber } from "../utils/helperFunctions";

export const EditableModal = (props) => {
  const { actionLabel, actionOnClick, title, configs = [], currentValue } = props;
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(currentValue);
  const [errMsg, setErrMsg] = useState("");
  useEffect(() => {
    setData(
      configs.reduce(
        (accumulate, item) => ({
          ...accumulate,
          [item.fieldName]: currentValue[item.fieldName] || "",
        }),
        {}
      )
    );
  }, [showModal]);

  const onChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  var modalBody = (
    <div>
      <Form>
        {configs.map((item) => {
          // const item = configs.find((config) => config.fieldName == attr);
          const { fieldName, label, inputType, hideWhenEdit, required } = item;
          if (hideWhenEdit) {
            return null;
          }
          const displayLabel = required ? label + "*" : label;
          switch (inputType) {
            case INPUT_TYPE.TEXT:
            case INPUT_TYPE.EMAIL:
              return (
                <div key={fieldName} style={{ margin: "10px 0" }}>
                  <Form.Label>{displayLabel}</Form.Label>
                  <div style={{ display: "flex" }}>
                    <Form.Control type="text" id={fieldName} value={data[fieldName]} onChange={onChange} />
                  </div>
                </div>
              );
            case INPUT_TYPE.NUMBER:
              return (
                <div key={fieldName} style={{ margin: "10px 0" }}>
                  <Form.Label>{displayLabel}</Form.Label>
                  <div style={{ display: "flex" }}>
                    <Form.Control type="number" id={fieldName} value={data[fieldName]} onChange={onChange} />
                  </div>
                </div>
              );
            case INPUT_TYPE.TEXT_AREA:
              return (
                <div key={fieldName} style={{ margin: "10px 0" }}>
                  <Form.Label>{displayLabel}</Form.Label>
                  <div style={{ display: "flex" }}>
                    <Form.Control as="textarea" rows={3} id={fieldName} value={data[fieldName]} onChange={onChange} />
                  </div>
                </div>
              );
            case INPUT_TYPE.DATE:
              return (
                <div key={fieldName} style={{ margin: "10px 0" }}>
                  <Form.Label>{displayLabel}</Form.Label>
                  <div style={{ display: "flex" }}>
                    <Form.Control type="date" id={fieldName} value={data[fieldName]} onChange={onChange} />
                  </div>
                </div>
              );
            case INPUT_TYPE.BOOLEAN:
              return (
                <div key={fieldName} style={{ margin: "10px 0" }}>
                  {/* <Form.Label>{displayLabel}</Form.Label> */}
                  <div style={{ display: "flex" }}>
                    <Form.Check
                      type="switch"
                      label={displayLabel}
                      id={fieldName}
                      checked={data[fieldName]}
                      onChange={(e) => {
                        setData((prevState) => ({
                          ...prevState,
                          [fieldName]: !data[fieldName],
                        }));
                      }}
                    />
                  </div>
                </div>
              );
            case INPUT_TYPE.SELECT:
              return (
                <div key={fieldName} style={{ margin: "10px 0" }}>
                  <Form.Label>{displayLabel}</Form.Label>
                  <div style={{ display: "flex" }}>
                    <SelectSearch
                      options={item.enumValues}
                      onChange={(val) => {
                        setData((prevState) => ({
                          ...prevState,
                          [fieldName]: val,
                        }));
                      }}
                      value={data[fieldName]}
                      name={fieldName}
                      placeholder={label}
                      search={true}
                    />
                  </div>
                </div>
              );
            case INPUT_TYPE.FILE:
              return (
                <div key={fieldName} style={{ margin: "10px 0" }}>
                  <Form.Label>{displayLabel}</Form.Label>
                  <div style={{ display: "flex" }}>
                    <FileUploader
                      handleChange={(file) => {
                        setData({
                          ...data,
                          [fieldName]: file,
                        });
                      }}
                      multiple={false}
                      hoverTitle={"Drop the videos here"}
                      types={item.fileTypes}
                    />
                  </div>
                </div>
              );
          }
          return (
            <div className="form-group" key={fieldName} style={{ margin: "10px 0" }}>
              NOT SUPPORTED INPUT
            </div>
          );
        })}
      </Form>
    </div>
  );
  return !showModal ? (
    <div>
      <Button variant="success" onClick={() => setShowModal(true)}>
        {title}
      </Button>
    </div>
  ) : (
    <div>
      <Modal show={showModal}>
        <Modal.Header closeButton onClick={() => setShowModal(false)}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {modalBody} <div style={{ margin: "20px", color: "red" }}>{errMsg}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button
            variant="dark"
            onClick={() => {
              var msg = "";
              var updatedValues = {};
              for (var i = 0; i < configs.length; i++) {
                const config = configs[i];
                const { required, fieldName, label, inputType, hideWhenEdit } = config;
                if (hideWhenEdit) {
                  continue;
                }
                const fieldValue = data[fieldName];
                const currentFieldValue = currentValue[fieldName];
                if (required && (!fieldValue || fieldValue.length === 0)) {
                  msg += `--- ${label} cannot be empty. ---`;
                }
                if (required && inputType === INPUT_TYPE.EMAIL && fieldValue && !isValidEmail(fieldValue)) {
                  msg += `--- ${label} must be in the format of email. ---`;
                } else if (inputType === INPUT_TYPE.NUMBER && !isValidNumber(fieldValue)) {
                  msg += `--- ${label} must be number. ---`;
                }
                if (currentFieldValue != fieldValue || currentFieldValue?.name != fieldValue?.name) {
                  updatedValues = {
                    ...updatedValues,
                    [fieldName]: fieldValue,
                  };
                }
                if (inputType === INPUT_TYPE.BOOLEAN) {
                  updatedValues = {
                    ...updatedValues,
                    [fieldName]: Boolean(fieldValue),
                  };
                }
              }
              if (msg.length > 0) {
                setErrMsg(msg);
                return;
              }
              actionOnClick(updatedValues);
              setShowModal(false);
              setData({});
            }}
          >
            {actionLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
