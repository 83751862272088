export default function DisplayItem(props) {
  const { label, value, onClick } = props;
  const clickableStyle = onClick
    ? { cursor: "pointer", textDecoration: "underline" }
    : {};
  return (
    <div style={{ display: "flex", ...clickableStyle }} onClick={onClick}>
      <label>{label}: </label>
      <div style={{ margin: "0 10px" }}></div>
      <div>{value}</div>
    </div>
  );
}
