// db.js
import Dexie from "dexie";
import localFileStorage from "./localFileStorage";

const metadataDB = new Dexie("CustomerLinkHubDB");

metadataDB.version(1).stores({
  caseMetaDataTable: "&caseId, lastModifiedAt, insertedAt, lastCommentedAt, lastEmailedAt, lastNotedAt",
});

async function putItemTocaseMetaDataTable(item) {
  const insertedAt = new Date().toISOString();
  return metadataDB.caseMetaDataTable.put({ ...item, insertedAt });
}

async function getcaseMetaDataTableById(caseId) {
  const metadataItem = await metadataDB.caseMetaDataTable.get(caseId);
  return { ...metadataItem };
}

function customSort(a, b) {
  const nameA = a.name;
  const nameB = b.name;
  const numA = parseFloat(nameA);
  const numB = parseFloat(nameB);

  if (!isNaN(numA) && !isNaN(numB)) {
    if (numA !== numB) {
      return numA - numB;
    } else {
      return nameA.localeCompare(nameB);
    }
  } else if (!isNaN(numA)) {
    return -1;
  } else if (!isNaN(numB)) {
    return 1;
  } else {
    return nameA.localeCompare(nameB);
  }
}

async function deleteAllCaches() {
  const caseMetaDataTable = (await metadataDB.caseMetaDataTable.toArray()) || [];
  // const playHistory = (await metadataDB.playHistory.toArray()) || [];
  caseMetaDataTable.forEach((page) => {
    const { caseId } = page;
    metadataDB.caseMetaDataTable.delete(caseId);
  });
  return caseMetaDataTable.length;
}

// async function getLocalPlayHistory(id) {
//   if (id) {
//     return metadataDB.playHistory.where("caseId").equalsIgnoreCase(id).toArray();
//   }
//   return metadataDB.playHistory.toArray();
// }

// async function deleteItemFromEpisodeData(id) {
//   return metadataDB.movieEpisodeData.delete(id);
// }

export default {
  putItemTocaseMetaDataTable,
  getcaseMetaDataTableById,
  deleteAllCaches,
};
