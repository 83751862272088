import { useContext, useEffect, useState } from "react";
import { Button, Pagination } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import SelectSearch from "react-select-search";

import CustomTable from "../components/customTable";
import AppContext from "../context/app-context";
import { CASE_STATUS_ENUM, CaseDetailConfig, TimeRelatedFields } from "../inputConfigs/caseConfig";
import { EditableModal } from "../components/editableModal";
import { INPUT_TYPE } from "../constants";
import userProfileService from "../services/userProfileService";
import { toast } from "react-toastify";
import { rightAlignStyle } from "./agentCaseDetailPage";
import TimeWidget from "../widget/TimeWidget";
import localMetadataDB from "../storage/localMetadataDB";
import TitleWithNotificationDot from "../widget/TitleWithNotificationDot";

export default function CaseListPage() {
  const {
    currentProfile,
    setAppRefresh,
    setLoading,
    caseList = [],
    queryQueueId,
    queryStatus,
    setQueryQueueId,
    setQueryStatus,
    currentPage,
    onNextPage,
    hasNextPage,
    onPrevPage,
    queuesSelectsOptions,
    setCaseListRefresh,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const {
    agentProfile: { defaultQueueId, storeId, defaultStatus = "" } = {},
    queueList: { queues = [] } = {},
    storeDetails: { billingPlan, storeName } = {},
  } = currentProfile || {};
  const [localCaseMetadata, setLocalCaseMetadata] = useState([]);
  let selectedQueue = queues.find((item) => item.queueId === queryQueueId) || {};

  async function loadLocalData() {
    const locals = await Promise.all(
      caseList.map(({ caseId }) => {
        return localMetadataDB.getcaseMetaDataTableById(caseId);
      })
    );
    setLocalCaseMetadata(locals);
    return locals;
  }
  useEffect(() => {
    if (caseList.length > 0) loadLocalData();
  }, [caseList]);
  const displayHeaders = [
    { label: "View Case", fieldName: "ViewDetails" },
    { label: "Title", fieldName: "title" },
    { label: "Status", fieldName: "caseStatus" },
    { label: "Customer Email", fieldName: "customerEmail" },
    { label: "Customer Firstname", fieldName: "customerFirstname" },
    { label: "Customer Lastname", fieldName: "customerLastname" },
    { label: "Last Modified By", fieldName: "lastModifiedBy" },
    { label: "Last Modified At", fieldName: "lastModifiedAt" },
    { label: "Created At", fieldName: "createdAt" },
    // { label: "", fieldName: "" },
  ];

  const displayingCase = caseList.map((item) => {
    return displayHeaders.map(({ fieldName }) => {
      if (fieldName === "ViewDetails") {
        const localItem = localCaseMetadata.find((lo) => lo.caseId === item.caseId);
        return {
          // onClick: () => {
          //   console.log("View ", item);
          // },
          //   value: <Button>View</Button>,
          value: (
            <TitleWithNotificationDot
              showNotification={!localItem || localItem.lastModifiedAt !== item.lastModifiedAt}
              title={<Link to={`/view/${item.caseId}`}>View</Link>}
            />
          ),
        };
      }
      if (TimeRelatedFields.find((item) => item === fieldName)) {
        return { value: <TimeWidget value={item[fieldName]} /> };
      }
      return { value: item[fieldName] };
    });
  });

  return (
    <div>
      <div>
        <div style={rightAlignStyle}>
          <EditableModal
            title={"Create Case"}
            // disabled={!featureFlags.adHocCaseCreationEnable}
            currentValue={{}}
            configs={[
              ...CaseDetailConfig,
              {
                label: "Automatically Send email to notify the customer.",
                fieldName: "sendInvitationEmail",
                inputType: INPUT_TYPE.BOOLEAN,
              },
            ].map((item) => {
              if (item.fieldName === "queueId") {
                return {
                  ...item,
                  enumValues: queuesSelectsOptions,
                };
              }
              return item;
            })}
            actionLabel={"Create Case"}
            actionOnClick={async (data) => {
              const createCaseRequest = { ...data, storeId };
              setLoading(true);
              userProfileService.createCase({
                ...createCaseRequest,
                callback: ({ error, createdCase }) => {
                  setLoading(false);
                  if (error) {
                    toast.error(error);
                    return;
                  }
                  toast.success(`${createdCase.title} is created, click view it now!`, {
                    onClick: () => {
                      navigate(`/view/${createdCase.caseId}`);
                    },
                  });
                },
              });
            }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-around", margin: "10px 0", flexWrap: "wrap" }}>
          <div>
            <Button variant="info" onClick={() => setCaseListRefresh((prev) => prev + 1)}>
              Refresh
            </Button>
          </div>
          <div style={{ display: "flex" }}>
            <h5 style={{ margin: "auto 5px" }}>Queue:</h5>
            <SelectSearch
              options={queues.map((item) => {
                const { queueName, queueId } = item;
                return { name: queueName, value: queueId };
              })}
              onChange={(val) => {
                setQueryQueueId(val);
              }}
              value={queryQueueId}
              name={"queryQueueId"}
              placeholder={"Select the queue to query on"}
              search={true}
            />
          </div>
          <div style={{ display: "flex" }}>
            <h5 style={{ margin: "auto 5px" }}>Status:</h5>
            <SelectSearch
              options={[{ name: "All Status", value: "" }, ...CASE_STATUS_ENUM]}
              onChange={(val) => {
                setQueryStatus(val);
              }}
              value={queryStatus}
              name={"queryStatus"}
              placeholder={"Select the statu to query on"}
              search={true}
            />
          </div>
        </div>
      </div>
      <div>
        <CustomTable headers={displayHeaders} rows={displayingCase} colCanSort={displayHeaders.map((item) => false)} />
      </div>
      <div>
        <Pagination>
          <Pagination.Prev
            disabled={currentPage === 0}
            onClick={() => {
              onPrevPage();
            }}
          />
          <Pagination.Item>{currentPage + 1}</Pagination.Item>
          <Pagination.Next
            disabled={!hasNextPage}
            onClick={() => {
              onNextPage();
            }}
          />
        </Pagination>
      </div>
    </div>
  );
}
