import "./css/App.css";
import "./css/reactSearchStyle.css";
// import "react-select-search/style.css";

import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import AuthenticateWrapper from "./components/authenticateWrapper";
import Header from "./components/header";
import Login from "./components/Login";
import AppContext from "./context/app-context";
import useAuth from "./context/useAuth";
import UserProfilePage from "./page/userProfilePage";
import CaseDetails from "./page/caseDetailPage";
import PrivacyPolicy from "./page/PrivacyPolicy";
import FeaturesPage from "./page/FeaturePage";
import userProfileService from "./services/userProfileService";
import CaseListPage from "./agentPages/caseListPage";
import AgentCaseDetailPage from "./agentPages/agentCaseDetailPage";
import localConfig from "./utils/localConfig";
import Home from "./page/homePageSrc/Home";
import StoreDetailPage from "./agentPages/StoreDetailPage";
import { FREE_PLAN, FREE_TRAIL } from "./inputConfigs/storeConfig";
import { getDifferenceInDay } from "./utils/helperFunctions";

export const APP_ROUTE_LIST = [
  {
    path: "/",
    // element: <FeaturesPage />,
    element: <Home />, // <AuthenticateWrapper components={<TicTacToe />} />,
  },
  {
    path: "/login",
    element: <AuthenticateWrapper components={<Login />} />,
  },
  {
    path: "/store-profile",
    element: <AuthenticateWrapper components={<StoreDetailPage />} />,
  },
  {
    path: "/profile",
    element: <AuthenticateWrapper components={<UserProfilePage />} />,
  },
  {
    path: "/home",
    element: <AuthenticateWrapper components={<CaseListPage />} />,
  },
  {
    path: "/view/:caseId",
    element: <AuthenticateWrapper components={<AgentCaseDetailPage />} />,
  },
  {
    path: "/details/:caseId",
    element: <CaseDetails />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
];
function App() {
  const [loading, setLoading] = useState(false);
  const [appRefresh, setAppRefresh] = useState(0);
  const [caseListRefresh, setCaseListRefresh] = useState(0);
  const [userProfiles, setUserProfiles] = useState([]);
  const [currentProfile, setCurrentProfile2] = useState(null);
  const { currentUser, signOut } = useAuth();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCaseList, setPageCaseList] = useState([]);

  const setCurrentProfile = (profile) => {
    const { storeDetails: { billingPlan, storeName, createdAt } = {} } = profile || {};
    const storeAgeInDay = getDifferenceInDay(createdAt, new Date().toISOString());
    let updatedPlan = billingPlan;
    let updatedProfile = { ...profile };
    if (billingPlan === FREE_PLAN && storeAgeInDay <= 14) {
      updatedPlan = FREE_TRAIL;
      updatedProfile = {
        ...updatedProfile,
        storeDetails: {
          ...updatedProfile.storeDetails,
          billingPlan: updatedPlan,
          freeTrailEndInDays: Math.floor(14 - storeAgeInDay),
        },
      };
    }

    setCurrentProfile2(updatedProfile);
  };
  useEffect(() => {}, [appRefresh, currentUser]);

  function onCaseChange(item) {
    if (!item || !item.caseId) {
      return;
    }
    setPageCaseList((prev) => {
      return prev.map((page) => {
        const { caseList = [] } = page;
        return {
          ...page,
          caseList: caseList.map((caseItem) => {
            if (caseItem.caseId === item.caseId) {
              const updatedItem = { ...caseItem, ...item };
              return updatedItem;
            }
            return caseItem;
          }),
        };
      });
    });
  }
  useEffect(() => {
    setLoading(true);
    userProfileService.getUserProfile({
      callback: ({ error, storeProfiles }) => {
        setLoading(false);
        if (error) {
          toast.error(error);
        }
        setUserProfiles(storeProfiles);
        let defaultStoreId = localConfig.getDefaultStoreId();
        if (currentProfile) {
          defaultStoreId = currentProfile.storeDetails?.storeId;
        }
        if (storeProfiles && storeProfiles.length > 0) {
          const defaultStore = storeProfiles.find((item) => item?.storeDetails?.storeId === defaultStoreId);
          if (defaultStore) {
            setCurrentProfile(defaultStore);
          } else setCurrentProfile(storeProfiles[0]);
        }
        if (storeProfiles && storeProfiles.length === 0 && currentUser) {
          toast.info("Please contact admin to provide you system access.");
        }
      },
    });
  }, [appRefresh, currentUser]);
  const {
    agentProfile: { defaultQueueId, storeId, defaultStatus = "" } = {},
    queueList: { queues = [] } = {},
    storeDetails: { billingPlan, storeName } = {},
  } = currentProfile || {};
  const queuesSelectsOptions = queues.map((queue) => ({
    name: queue.queueName,
    value: queue.queueId,
  }));

  // const [caseList, setCaseList] = useState([]);
  const [queryQueueId, setQueryQueueId] = useState("");
  const [queryStatus, setQueryStatus] = useState("");
  useEffect(() => {
    if (storeId && defaultQueueId) {
      setQueryQueueId(defaultQueueId);
      setQueryStatus(defaultStatus);
    }
  }, [storeId]);

  async function getCasesByQueueAndStatus(queueId, status, ExclusiveStartKey) {
    setLoading(true);
    userProfileService.getCases({
      storeId,
      queueId,
      queryStatus: status,
      ExclusiveStartKey,
      callback: ({ error, caseQueryResponse }) => {
        setLoading(false);
        if (error) {
          toast.error(error);
        }
        // const { caseList, LastEvaluatedKey } = caseQueryResponse;
        setPageCaseList((prev) => [...prev, caseQueryResponse]);
      },
    });
  }
  useEffect(() => {
    if (queryQueueId) {
      setPageCaseList([]);
      setCurrentPage(0);
      getCasesByQueueAndStatus(queryQueueId, queryStatus);
    }
  }, [queryQueueId, queryStatus, caseListRefresh]);

  function onNextPage() {
    if (currentPage === pageCaseList.length - 1) {
      getCasesByQueueAndStatus(queryQueueId, queryStatus, pageCaseList[pageCaseList.length - 1]?.LastEvaluatedKey);
      setCurrentPage((prev) => prev + 1);
    } else {
      setCurrentPage((prev) => prev + 1);
    }
  }
  function onPrevPage() {
    setCurrentPage((prev) => prev - 1);
  }
  return (
    <div className="App">
      <BrowserRouter>
        <AppContext.Provider
          value={{
            loading,
            setLoading,
            setAppRefresh,
            currentProfile,
            setUserProfiles,
            userProfiles,
            setCurrentProfile,
            queuesSelectsOptions,
            caseList: pageCaseList[currentPage]?.caseList,
            hasNextPage: Boolean(pageCaseList[currentPage]?.LastEvaluatedKey),
            currentPage,
            onNextPage,
            onPrevPage,
            queryQueueId,
            queryStatus,
            setQueryQueueId,
            setQueryStatus,
            onCaseChange,
            currentUser,
            setCaseListRefresh,
          }}
        >
          <>
            {loading && (
              <div className="fadeMe loader-container">
                <span className="loader"></span>
              </div>
            )}
            <Header />
            <div className="">
              <Routes>
                {APP_ROUTE_LIST.map((item) => {
                  return <Route key={item.path} path={item.path} element={item.element} />;
                })}
              </Routes>
            </div>
          </>
        </AppContext.Provider>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
