import { INPUT_TYPE } from "../constants";

export const USER_EDIT_CONFIGS = [
  {
    fieldName: "firstName",
    label: "First Name",
    inputType: INPUT_TYPE.TEXT,
    required: true,
  },
  {
    fieldName: "middleName",
    label: "Middle Name",
    inputType: INPUT_TYPE.TEXT,
  },
  {
    fieldName: "lastName",
    label: "Last Name",
    inputType: INPUT_TYPE.TEXT,
    required: true,
  },
  {
    fieldName: "phoneNumber",
    label: "Phone Number",
    inputType: INPUT_TYPE.TEXT,
  },
  {
    fieldName: "homeAddress",
    label: "Home Address",
    inputType: INPUT_TYPE.TEXT_AREA,
  },

  // {
  //   fieldName: "description",
  //   label: "description",
  //   inputType: INPUT_TYPE.TEXT_AREA,
  // },
  // {
  //   fieldName: "type",
  //   label: "type",
  //   enumValues: ["", "动漫", "电影", "儿童", "电视剧"],
  //   inputType: INPUT_TYPE.SELECT,
  // },
];
