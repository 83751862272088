import axios from "axios";

import { handleAPICallError } from "../utils/helperFunctions";

export const RequestStatus = {
  PENDING: "Pending",
  APPROVED: "Approved",
  CANCENLS: "Canceled",
  REJECTED: "Rejected",
};

const SERVICE_URL = "https://public.service.traveai.com";

const client = axios.create({
  baseURL: SERVICE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "x-api-key": "i24oi12hr1lk2nf1uy24r12idcfikjnqklwdj2oeu2iy4iu12r2po89021749t7b2jbkskallasjbgcwbcjkisbm",
  },
});

async function getCaseDetails({ caseId, email, messageExcludeKey, callback }) {
  // if (!(await isUserLogined())) {
  //   callback({});
  //   return;
  // }
  // const user = await Auth.currentAuthenticatedUser();

  // const jwtToken = getUserJwtToken(user);
  return client
    .post(`get-case`, { caseId, email, messageExcludeKey })
    .then((res) => {
      //   console.log("Success:", res);
      const { data } = res;
      callback(data);
    })
    .catch((err) => {
      handleAPICallError({ callback, err });
    });
}

async function updateCase({ callback, ...request }) {
  return client
    .post(`update-case`, request)
    .then((res) => {
      const { data } = res;
      callback(data);
    })
    .catch((err) => {
      handleAPICallError({ callback, err });
    });
}

export default {
  getCaseDetails,
  updateCase,
};
