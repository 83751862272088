import { INPUT_TYPE } from "../constants";
export const FREE_TRAIL = "Free Trail";
export const FREE_PLAN = "Free Plan";
export const BASIC_PLAN = "Basic Plan";
export const ADVANCE_PLAN = "Advance Plan";
export const ENTERPRISE_PLAN = "Enterprise Plan";

export const StoreUpdateConfig = [
  {
    label: "Default Queue",
    fieldName: "defaultQueueId",
    inputType: INPUT_TYPE.SELECT, // add enum
    tooltip: "This default Queue will assign the case to",
    disable: false,
  },
  {
    label: "Confirmation Email Enable",
    fieldName: "confirmationEmailEnable",
    inputType: INPUT_TYPE.BOOLEAN, // add enum
    tooltip: "Automatically send email to customer when order is received",
    disable: false,
  },
  {
    label: "Confirmation Email Template",
    fieldName: "confirmationEmailTemplate",
    inputType: INPUT_TYPE.TEXT_AREA,
    tooltip: "The System will use this as the template to send confirmation email.",
    disable: false,
  },
];

export const CreateUserConfig = [
  {
    label: "First Name",
    fieldName: "firstname",
    inputType: INPUT_TYPE.TEXT,
    disable: false,
    required: true,
  },
  {
    label: "Last Name",
    fieldName: "lastname",
    inputType: INPUT_TYPE.TEXT,
    disable: false,
    required: true,
  },
  {
    label: "Email Address - User will be using this email to login",
    fieldName: "emailAddress",
    inputType: INPUT_TYPE.EMAIL,
    disable: false,
    required: true,
  },
];
