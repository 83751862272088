import { useContext, useEffect, useState } from "react";
import AppContext from "../context/app-context";
import { CgProfile } from "react-icons/cg";
import { EditableModal } from "../components/editableModal";
import { USER_EDIT_CONFIGS } from "../inputConfigs/userInputConfig";
import DisplayItem from "../widget/displayItem";
import userProfileService from "../services/userProfileService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  UPDATE_POSITION_CONFIGS,
  getInputTypeWithManager,
} from "../inputConfigs/newUserInputConfig";
import { INPUT_TYPE } from "../constants";
import CustomTable from "../components/customTable";
import { toast } from "react-toastify";

export default function UserProfilePage(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const viewUserId = searchParams.get("userId");
  const navigate = useNavigate();

  const [viewingProfile, setViewingProfile] = useState(null);
  const [directReports, setDirectReports] = useState([]);
  const {
    userProfile = {},
    setAppRefresh,
    setCurrentProfile,
    setLoading,
    teamMembers,
  } = useContext(AppContext);
  const {
    companyId,
    profileType: loginUserProfile,
    userId: loginUserId,
  } = userProfile;

  const [refresh, setRefresh] = useState(0);
  useEffect(() => {
    if (!viewUserId && loginUserId) {
      setSearchParams({ userId: loginUserId });
    }
  }, [viewUserId, loginUserId]);
  function refreshData() {
    setLoading(true);
    userProfileService.getOtherUserProfile({
      companyId,
      userId: viewUserId,
      callback: ({
        userProfile: viewProfile,
        directReports: directs = [],
        error,
      }) => {
        setLoading(false);
        if (error) {
          toast.error("Recieved error while getting profile: " + error);
          return;
        }
        setViewingProfile(viewProfile);
        setDirectReports(directs);
      },
    });
  }
  useEffect(() => {
    if (
      viewUserId &&
      viewUserId.length > 0 &&
      companyId &&
      companyId.length > 0
      // &&
      // (!viewingProfile || viewingProfile?.userId !== viewUserId)
    ) {
      refreshData();
    }
  }, [viewUserId, companyId, refresh]);

  const showingProfile =
    viewUserId !== loginUserId ? viewingProfile || {} : userProfile;

  const {
    emailAddress,
    firstName,
    lastName,
    middleName = "",
    employmentType,
    jobLevel,
    jobTitle,
    phoneNumber,
    homeAddress,
    managerFullName,
    managerId,
    employmentStatus,
    lastHiredDate,
    userId: userIdInDisplay,
  } = showingProfile;

  const canSeeEmployeePTO =
    loginUserProfile === "ADMIN" ||
    managerId === loginUserId ||
    viewUserId === loginUserId;
  return (
    <div>
      <div
        style={{
          border: "1px solid #ccc",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
        >
          <div
            style={{
              marginRight: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "250px",
                height: "250px",
                border: "1px solid #ccc",
                borderRadius: "50%",
                overflow: "hidden",
              }}
            >
              <CgProfile style={{ width: "100%", height: "100%" }} />
            </div>
          </div>
          <div style={{ padding: "10px", margin: "10px 0" }}>
            <DisplayItem
              label={"Name"}
              value={`${firstName} ${middleName} ${lastName}`}
            />
            <DisplayItem label={"Email"} value={emailAddress} />
            <DisplayItem label={"Phone Number"} value={phoneNumber} />
            <DisplayItem label={"Level"} value={jobLevel} />
            <DisplayItem label={"Title"} value={jobTitle} />
            {/* <DisplayItem label={"Employment Status"} value={employmentStatus} /> */}
            <DisplayItem
              label={"Last Hired Date"}
              value={new Date(lastHiredDate).toDateString()}
            />
            <DisplayItem
              label={"Manager Name"}
              value={managerFullName}
              onClick={() => {
                setSearchParams({ userId: managerId });
              }}
            />
            <DisplayItem label={"Employment Type"} value={employmentType} />
            <DisplayItem label={"Home Address"} value={homeAddress} />
            {canSeeEmployeePTO && (
              <DisplayItem
                label={"View PTO Hours"}
                value={""}
                onClick={() => {
                  navigate(`/pto?userId=${userIdInDisplay}`);
                }}
              />
            )}
            {viewUserId === loginUserId && (
              <div style={{ margin: "20px 0" }}>
                <EditableModal
                  title={"Edit"}
                  currentValue={userProfile}
                  configs={USER_EDIT_CONFIGS}
                  actionLabel={"Submit"}
                  actionOnClick={(data) => {
                    setLoading(true);
                    userProfileService.updateUseridentity({
                      payload: data,
                      callback: ({ error }) => {
                        setLoading(false);
                        if (error) {
                          toast.error(
                            "Recieved error while getting profile: " + error
                          );
                          return;
                        }
                        setCurrentProfile({ ...userProfile, ...data });
                        setRefresh(refresh + 1);
                        toast.info("Successfuly created profile information.");
                      },
                    });
                  }}
                />
              </div>
            )}
            {loginUserProfile === "ADMIN" && (
              <div style={{ margin: "20px 0" }}>
                <EditableModal
                  title={"Update Position"}
                  currentValue={{
                    ...showingProfile,
                    employmentType: [{ name: employmentType }],
                    manager: [
                      {
                        ...teamMembers.find((mem) => mem.userId === managerId),
                        name: managerFullName,
                      },
                    ],
                    lastHiredDate: new Date(lastHiredDate),
                    employmentStatus: [{ name: employmentStatus }],
                  }}
                  configs={getInputTypeWithManager(
                    UPDATE_POSITION_CONFIGS,
                    teamMembers
                  )}
                  actionLabel={"Submit"}
                  actionOnClick={(data) => {
                    console.log(data.employmentType);
                    const {
                      employmentStatus: [{ name: employmentStatus }] = [{}],
                      manager: [
                        { userId: managerId, fullName: managerFullName },
                      ] = [{}],
                      employmentType: [{ name: employmentType }] = [{}],
                      ...payload
                    } = data;
                    setLoading(true);
                    userProfileService.updateJobPosition({
                      payload: {
                        ...payload,
                        employmentType,
                        managerId,
                        employmentStatus,
                        companyId,
                        userId: userIdInDisplay,
                        managerFullName,
                      },
                      callback: ({ identity, error }) => {
                        setLoading(false);
                        if (error) {
                          toast.error(
                            "Recieved error while getting profile: " + error
                          );
                          return;
                        }
                        if (!viewUserId) {
                          setCurrentProfile({
                            ...userProfile,
                            ...identity,
                            managerFullName,
                          });
                        } else {
                          setViewingProfile((prev) => ({
                            ...prev,
                            ...identity,
                            managerFullName,
                          }));
                        }
                        toast.info("Successfuly updated user job information.");
                        setRefresh(refresh + 1);
                      },
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {directReports && directReports.length > 0 && (
        <div
          style={{
            border: "1px solid #ccc",
            padding: "20px",
            borderRadius: "10px",
            margin: "10px 0",
          }}
        >
          <h3>
            {viewUserId === loginUserId
              ? "My Direct Reports"
              : `${firstName}'s Direct Reports`}
          </h3>
          <CustomTable
            headers={["Full Name", "Job Title", "Job Level"]}
            colCanSort={[false, false, false]}
            rows={directReports.map((mem = {}) => {
              const { fullName, jobTitle, jobLevel, userId, managerId } = mem;
              const manager = teamMembers.find(
                (item) => item.userId === managerId
              );
              return [
                {
                  value: fullName,
                  onClick: () => navigate(`/profile?userId=${userId}`),
                },
                {
                  value: jobTitle,
                  onClick: () => navigate(`/profile?userId=${userId}`),
                },
                {
                  value: jobLevel,
                  onClick: () => navigate(`/profile?userId=${userId}`),
                },
              ];
            })}
          />
        </div>
      )}
    </div>
  );
}
