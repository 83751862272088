import React, { useContext, useEffect, useRef, useState } from "react";
import { Form, Button, Card, Accordion, ListGroup, ButtonGroup } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import userProfileService from "../services/userProfileService";
import AppContext from "../context/app-context";
import { toast } from "react-toastify";
import EmailDetails from "../widget/EmailDetails";
import { formatLocalTime, getTimeSinceDate } from "../utils/helperFunctions";
import TimeWidget from "../widget/TimeWidget";

const EmailEditorWrapper = (props) => {
  const { caseDetail = {}, setNewlyAddedEmail, displayingEmailEvents } = props;
  const { currentProfile, setAppRefresh, setLoading = () => {}, queuesSelectsOptions, currentUser } = useContext(AppContext);

  const [toEmails, setToEmails] = useState("");
  const [toEmailsError, setToEmailsError] = useState("");
  const [ccEmails, setCcEmails] = useState("");
  const [ccEmailsError, setCcEmailsError] = useState("");
  const [bccEmails, setBccEmails] = useState("");
  const [bccEmailsError, setBccEmailsError] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("<p><br></p><p><br></p><p><br></p><p><br></p><p><br></p>");
  const { customerEmail, caseId } = caseDetail || {};

  useEffect(() => {
    if (currentUser && currentUser.username) {
      setCcEmails(currentUser.username);
    }
  }, [currentUser]);
  useEffect(() => {
    if (customerEmail) setToEmails(customerEmail);
  }, [customerEmail]);
  const handleToChange = (e) => {
    const emails = e.target.value;
    setToEmails(emails);
    validateEmails(emails, setToEmailsError);
  };

  const handleCcChange = (e) => {
    const emails = e.target.value;
    setCcEmails(emails);
    if (emails) validateEmails(emails, setCcEmailsError);
    else {
      setCcEmailsError("");
    }
  };

  const handleBccChange = (e) => {
    const emails = e.target.value;
    setBccEmails(emails);
    if (emails) validateEmails(emails, setBccEmailsError);
    else {
      setBccEmailsError("");
    }
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleBodyChange = (value) => {
    setBody(value);
  };

  const validateEmails = (emails, setError) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailArray = emails.split(",").map((email) => email.trim());

    const invalidEmails = emailArray.filter((email) => !email.match(emailPattern));

    if (invalidEmails.length > 0) {
      setError("Please enter valid email addresses.");
    } else {
      setError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (toEmailsError || ccEmailsError || bccEmailsError) {
      // If any of the email addresses have errors, prevent submission
      return;
    }

    // Implement email sending logic here, such as using an email sending service or API.

    if (!subject) {
      toast.error("Your must enter a subject for the email!");
      return;
    }
    if (!body) {
      toast.error("Your must enter the email body for the email!");
      return;
    }
    setLoading(true);
    userProfileService.updateCase({
      caseId,
      newEmail: {
        toEmails: toEmails.split(","),
        ccEmails: ccEmails ? ccEmails.split(",") : [],
        bccEmails: bccEmails ? bccEmails.split(",") : [],
        subject,
        body,
      },
      callback: ({ error, caseDetail: details, emailAsset }) => {
        setLoading(false);
        if (error) {
          toast.error(error);
          return;
        }
        if (emailAsset && emailAsset.newEmail) {
          setNewlyAddedEmail((prev) => [emailAsset.newEmail, ...prev]);
        }
        toast.success("The Email is sent to " + toEmails);
        setBccEmails("");
        setSubject("");
        setBody("");
      },
    });
  };

  const quillRef = useRef(null);
  const emailEditorRef = useRef(null);
  // Function to set focus on the Quill editor
  const focusQuillEditor = () => {
    if (quillRef.current) {
      quillRef.current.focus();
    }
    if (emailEditorRef.current) {
      emailEditorRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "start" });
    }
  };

  return (
    <div>
      <Card ref={emailEditorRef}>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Label>Emails should separate by comma(,)</Form.Label>
            <Form.Group controlId="formToEmails">
              <Form.Label>To:</Form.Label>
              <Form.Control type="text" value={toEmails} onChange={handleToChange} />
              {toEmailsError && <Form.Text className="text-danger">{toEmailsError}</Form.Text>}
            </Form.Group>
            <Form.Group controlId="formCcEmails">
              <Form.Label>Cc:</Form.Label>
              <Form.Control type="text" value={ccEmails} onChange={handleCcChange} />
              {ccEmailsError && <Form.Text className="text-danger">{ccEmailsError}</Form.Text>}
            </Form.Group>
            <Form.Group controlId="formBccEmails">
              <Form.Label>Bcc:</Form.Label>
              <Form.Control type="text" value={bccEmails} onChange={handleBccChange} />
              {bccEmailsError && <Form.Text className="text-danger">{bccEmailsError}</Form.Text>}
            </Form.Group>
            <Form.Group controlId="formSubject">
              <Form.Label>Subject:</Form.Label>
              <Form.Control type="text" value={subject} onChange={handleSubjectChange} />
            </Form.Group>
            <Form.Group controlId="formBody">
              <Form.Label>Body:</Form.Label>
              <ReactQuill
                value={body}
                onChange={handleBodyChange}
                ref={quillRef}
                modules={{
                  toolbar: [
                    [{ header: "1" }, { header: "2" }, { font: [] }],
                    [{ size: [] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                    ["link", "image", "video"],
                    ["clean"],
                  ],
                }}
              />
            </Form.Group>
            <div style={{ marginTop: "10px" }}>
              <Button variant="primary" type="submit">
                Send Email
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
      <div style={{ margin: "10px 5px" }}>
        <Accordion defaultActiveKey={["0"]}>
          {displayingEmailEvents.map((asset, index) => {
            const { emailSubject, fromEmail = "", toEmails, ccEmails, createdAt, caseId, lastModifiedBy } = asset;

            let { emailBody } = asset;
            emailBody = emailBody.replaceAll("<p><br></p>", "");
            //.replaceAll("<div><br>\n</div>", "");
            function buildBody() {
              const body = `<p><br></p><p><br></p><div><p>On ${new Date(
                createdAt
              ).toLocaleString()} ${fromEmail} wrote: <br></p><blockquote>${emailBody}</blockquote></div><p><br></p>`;
              return body;
            }
            return (
              <Accordion.Item eventKey={`${index}`} key={createdAt}>
                <Accordion.Header>
                  <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                    <div>
                      {" "}
                      <TimeWidget value={createdAt} />{" "}
                    </div>
                    {"::"} From:{" "}
                    <div style={{ margin: "0 5px" }}>
                      {" "}
                      <strong> {fromEmail.includes(caseId) ? lastModifiedBy : fromEmail}</strong>{" "}
                    </div>
                    <div>
                      {" Subject: "}
                      <strong>{emailSubject}</strong>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                        <div>
                          <strong>From:</strong> {fromEmail}
                        </div>
                        <EmailActionButtons
                          onReply={() => {
                            setSubject(emailSubject.includes("Re") ? emailSubject : `Re: ${emailSubject}`);
                            setBody(buildBody());
                            if (fromEmail.includes(caseId)) {
                              toast.info(`Skiping System Email ${fromEmail}`);
                            } else setToEmails(fromEmail);
                            setCcEmails("");
                            focusQuillEditor();
                          }}
                          onReplyAll={() => {
                            setSubject(emailSubject.includes("Re") ? emailSubject : `Re: ${emailSubject}`);
                            setBody(buildBody());
                            setToEmails(
                              [...toEmails, fromEmail].filter((item) => !item.includes("travel-llm.com")).join(",")
                            );
                            setCcEmails(ccEmails.filter((item) => !item.includes("travel-llm.com")).join(","));
                            focusQuillEditor();
                          }}
                          onForward={() => {
                            setSubject(emailSubject.includes("Fw") ? emailSubject : `Fw: ${emailSubject}`);
                            setBody(buildBody());
                            setToEmails("");
                            setCcEmails("");
                            focusQuillEditor();
                          }}
                        />
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>To:</strong> {toEmails.join(", ")}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Subject:</strong> {emailSubject}
                    </ListGroup.Item>
                    {ccEmails.length > 0 && (
                      <ListGroup.Item>
                        <strong>Cc:</strong> {ccEmails.join(", ")}
                      </ListGroup.Item>
                    )}
                    <ListGroup.Item dangerouslySetInnerHTML={{ __html: emailBody }}></ListGroup.Item>
                  </ListGroup>
                </Accordion.Body>
              </Accordion.Item>
            );
            // return (
            //   <EmailDetails
            //     setSubject={setSubject}
            //     setBody={setBody}
            //     setCcEmails={setCcEmails}
            //     setToEmails={setToEmails}
            //     asset={asset}
            //     key={createdAt}
            //     focusQuillEditor={focusQuillEditor}
            //   />
            // );
          })}
        </Accordion>
      </div>
    </div>
  );
};

function EmailActionButtons({ onReply, onReplyAll, onForward }) {
  return (
    <ButtonGroup>
      <Button onClick={onReply} variant="success">
        Reply
      </Button>
      <Button onClick={onReplyAll} variant="success">
        Reply All
      </Button>
      <Button onClick={onForward} variant="success">
        Forward
      </Button>
    </ButtonGroup>
  );
}

export default EmailEditorWrapper;
