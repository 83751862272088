import Auth from "@aws-amplify/auth";
import { useEffect, useState } from "react";
import { Hub } from "@aws-amplify/core";

const getCurrentUser = async () => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (_a) {
    // currentAuthenticatedUser throws an Error if not signed in
    return null;
  }
};
const useAuth = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const updateUser = async () => {
      setCurrentUser(await getCurrentUser());
      setLoading(false);
    };
    Hub.listen("auth", updateUser); // listen for login/signup events
    updateUser(); // check manually the first time because we won't get a Hub event
    return () => Hub.remove("auth", updateUser);
  }, []);
  const signIn = () => Auth.federatedSignIn();
  const signOut = () => Auth.signOut();
  return { currentUser, signIn, signOut, loading };
};

const isUserLogined = async () => {
  return (await getCurrentUser()) !== null;
};
export default useAuth;
export { getCurrentUser, isUserLogined };
