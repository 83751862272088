import React, { useState, useEffect, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Container, Row, Col, Button, Card, Form } from "react-bootstrap";
import customerCaseService from "../services/customerCaseService";
import { toast } from "react-toastify";
import AppContext from "../context/app-context";
import { formatLocalTime } from "../utils/helperFunctions";

const CaseDetails = (props) => {
  const { setLoading } = useContext(AppContext);
  const { caseId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");

  const [caseDetail, setCaseDetail] = useState({});
  const [excludeMesgKey, setExcludeMesgKey] = useState(null);
  const [caseMessage, setCaseMessage] = useState([]);
  const [message, setMessage] = useState("");

  const {
    title,
    description,
    caseStatus,
    lastModifiedBy,
    confirmationEmailSent,
    lastCommentedAt,
    lastCommentedBy,
    createdAt,
    lastModifiedAt,
  } = caseDetail;

  const isCaseOpened = caseStatus === "Open";

  const handleRequesUpdateCase = (caseStatus) => {
    setLoading(true);
    customerCaseService.updateCase({
      caseId,
      email,
      caseStatus: caseStatus,
      callback: ({ error, caseDetail }) => {
        setLoading(false);
        if (error) {
          toast.error(error);
          return;
        }
        toast.info(`Successfully change the case caseStatus to ${caseStatus}.`);
        setCaseDetail(caseDetail);
      },
    });
  };

  const handleMessageSubmit = () => {
    setLoading(true);
    customerCaseService.updateCase({
      caseId,
      email,
      newMessage: message,
      callback: ({ error, caseDetail, messageAsset: { newMessage } }) => {
        setLoading(false);
        if (error) {
          toast.error(error);
          return;
        }
        toast.info("Successfully added a new message.");
        setCaseDetail(caseDetail);
        setCaseMessage((prev) => [newMessage, ...prev]);
        setMessage("");
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    customerCaseService.getCaseDetails({
      caseId,
      email,
      callback: (data) => {
        setLoading(false);
        const { caseDetail, messageAsset: { assetList = [], LastEvaluatedKey } = {}, error } = data;
        if (error) {
          toast.error(error);
          return;
        }
        setCaseDetail(caseDetail);
        setExcludeMesgKey(LastEvaluatedKey);
        setCaseMessage(assetList);
      },
    });
  }, [caseId, email]);

  return (
    <Container>
      <Row className="mt-3">
        <Col>
          <Card>
            <Card.Body>
              <div style={{ margin: "10px 0", display: "flex", justifyContent: "space-between" }}>
                <Card.Title>Case Details</Card.Title>
                <Button onClick={() => handleRequesUpdateCase(isCaseOpened ? "Closed" : "Open")}>
                  {isCaseOpened ? "Resolve Case" : "Request to Open a Case"}
                </Button>
              </div>
              <Row>
                <Col md={6}>
                  <p>
                    <strong>Title:</strong> {title}
                  </p>
                  <p>
                    <strong>Description:</strong> {description}
                  </p>
                  <p>
                    <strong>Last Modified At:</strong> {formatLocalTime(lastModifiedAt)}
                  </p>
                </Col>
                <Col md={6}>
                  <p>
                    <strong>caseStatus:</strong> {caseStatus}
                  </p>
                  <p>
                    <strong>Last Modified By:</strong> {lastModifiedBy}
                  </p>
                  <p>
                    <strong>Created At:</strong> {formatLocalTime(createdAt)}
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {
        <Row className="mt-3">
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>Messages</Card.Title>
                <Form.Group controlId="messageTextarea">
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" rows={3} value={message} onChange={(e) => setMessage(e.target.value)} />
                </Form.Group>
                <div className="d-flex justify-content-end" style={{ margin: "10px 0" }}>
                  <Button disabled={!isCaseOpened} className="ml-2" onClick={handleMessageSubmit}>
                    Submit Message
                  </Button>
                </div>
                {caseMessage.map((msg, index) => (
                  <div className="message" key={index}>
                    <div className="message-header">
                      <span className="author">{msg.lastModifiedBy}</span>
                      <span className="time">{new Date(msg.lastModifiedAt).toLocaleString()}</span>
                    </div>
                    <div className="message-body">{msg.message}</div>
                  </div>
                ))}
                <Button
                  disabled={!excludeMesgKey}
                  onClick={() => {
                    setLoading(true);
                    customerCaseService.getCaseDetails({
                      caseId,
                      email,
                      messageExcludeKey: excludeMesgKey,
                      callback: (data) => {
                        setLoading(false);
                        const { caseDetail, messageAsset: { assetList = [], LastEvaluatedKey } = {}, error } = data;
                        if (error) {
                          toast.error(error);
                          return;
                        }
                        toast.info("Loaded more historical messages.");
                        setExcludeMesgKey(LastEvaluatedKey);
                        setCaseMessage((prev) => [...prev, ...assetList]);
                      },
                    });
                  }}
                >
                  Load more
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      }
    </Container>
  );
};

export default CaseDetails;
