import Auth from "@aws-amplify/auth";
import React, { useContext, useState } from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";
import SelectSearch from "react-select-search";

import AppContext from "../context/app-context";
import useAuth from "../context/useAuth";
import { NavDropdown } from "react-bootstrap";
import localConfig from "../utils/localConfig";

function Header() {
  // const navigrate = useNavigate();
  const { currentUser, signOut } = useAuth();
  const navigate = useNavigate();
  const { currentProfile, setAppRefresh, userProfiles = [], setCurrentProfile, setUserProfiles } = useContext(AppContext);

  const currentPath = window.location.pathname + window.location.search;
  const isMobile = window.innerWidth < 700;
  const href = window.location.href;
  const host = window.location.host;
  const path = href.substring(href.indexOf(host) + host.length);
  return (
    <header>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand
            onClick={() => {
              if (currentProfile) {
                navigate("/home");
              } else navigate("/");
            }}
          >
            <Card.Img
              variant="top"
              src={isMobile ? "favicon.ico" : "/apple-touch-icon.png"}
              width={isMobile ? undefined : "35px"}
              height={isMobile ? undefined : "35px"}
            />
            {/* <div>
              <FaPhoenixFramework
                style={{
                  backgroundColor: "black",
                  color: "#00FFFF",
                  width: "30",
                  height: "30",
                }}
              />
            </div> */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {currentProfile && (
                <Nav.Link active={path === "/store-profile"} onClick={() => navigate("/store-profile")}>
                  Store Profile
                </Nav.Link>
              )}
              {currentProfile && (
                <Nav.Link active={path === "/home"} onClick={() => navigate("/home")}>
                  Case List
                </Nav.Link>
              )}
              {/* {currentUser && <Nav.Link onClick={() => navigate(`/pto?userId=${userId}`)}>Pay Time OFF</Nav.Link>} */}
            </Nav>
            <Nav>
              {currentUser === null && <Nav.Link onClick={() => navigate(`/login?redirect=${currentPath}`)}>Login</Nav.Link>}
              {currentProfile && (
                <SelectSearch
                  options={userProfiles.map((item) => {
                    const {
                      agentProfile: { defaultQueueId, storeId, defaultStatus = "", emailAddress } = {},

                      storeDetails: { billingPlan, storeName } = {},
                    } = item;
                    return { name: `${storeName}`, value: storeId };
                  })}
                  onChange={(val) => {
                    localConfig.setDefaultStoreId(val);
                    setCurrentProfile(userProfiles.find((item) => item.agentProfile.storeId === val));
                    if (window.location.href.includes("view")) navigate("/home");
                  }}
                  value={currentProfile?.agentProfile?.storeId}
                  name={"queryQueueId"}
                  placeholder={"Select the store"}
                  search={true}
                />
              )}
              {/* {currentUser !== null && <Nav.Link onClick={() => navigate("/profile")}>Profile</Nav.Link>} */}
              <NavDropdown title="More" id="basic-nav-dropdown">
                <NavDropdown.Item active={path === "/"} onClick={() => navigate("/")}>
                  Features
                </NavDropdown.Item>
                {/* <NavDropdown.Item onClick={() => navigate("/privacy-policy")}>Privacy Policy</NavDropdown.Item> */}
                <NavDropdown.Divider />
                {currentUser !== null && (
                  <NavDropdown.Item
                    onClick={() => {
                      if (currentUser !== null) {
                        Auth.signOut();
                        navigate("/");
                        setCurrentProfile(null);
                        setUserProfiles([]);
                        setAppRefresh(Math.random());
                      }
                    }}
                  >
                    SignOut
                  </NavDropdown.Item>
                )}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* <LoginModel showModal={true} /> */}
    </header>
  );
}

export default Header;
