import React from "react";
import InformationCard from "./InformationCard";
import { faMessage, faBeer, faWifiStrong, faArrowsToEye, faReorder, faStickyNote } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Info.css";

function Info() {
  return (
    <div className="info-section" id="services">
      <div className="info-title-content">
        <h3 className="info-title">
          <span>What We Do</span>
        </h3>
        <p className="info-description">
          At Customer Link Hub, we're dedicated to providing you with a comprehensive customer service solution that goes
          beyond the basics. With Customer Link Hub, managing your customer service operations has never been easier.
          Experience the convenience of in-app email communication and robust email message tracking to streamline your
          workflow and deliver exceptional support. Try it out today!
        </p>
      </div>

      <div className="info-cards-content">
        <InformationCard
          title="📧 Seamless Email Communication and Tracking"
          description="Simplify your communication workflow with our integrated email feature. Handle all customer emails directly within our app while effortlessly tracking every exchange related to specific cases. Stay organized, ensure nothing falls through the cracks, and enable seamless follow-up for unparalleled customer support. "
          icon={faMessage}
        />
        <InformationCard
          title="✨ Efficient Order Management"
          description="Say goodbye to manual data entry! Our app automatically imports orders into the system, saving you valuable time and minimizing errors."
          icon={faBeer}
        />
        <InformationCard
          title="📦 Organized Case Management"
          description="Keep your support pipeline organized and efficient by categorizing customer cases into separate queues based on priority or type."
          icon={faReorder}
        />
        <InformationCard
          title="📝 Collaborative Team Notes"
          description="Foster collaboration and knowledge sharing within your team with internal notes, ensuring everyone stays informed and aligned."
          icon={faStickyNote}
        />
        <InformationCard
          title="🔔 Smart Message Notifications"
          description="Engage with customers effectively without bombarding them with notifications, thanks to our intelligent message notification system."
          icon={faWifiStrong}
        />
        <InformationCard
          title="🔍 Transparent Activity Tracking"
          description="Monitor and track all activities within support cases, providing transparency and accountability throughout the resolution process."
          icon={faArrowsToEye}
        />
      </div>
    </div>
  );
}

export default Info;
