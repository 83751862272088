import { useState } from "react";
import { Table } from "react-bootstrap";
import { BsSortAlphaDownAlt, BsSortAlphaUpAlt } from "react-icons/bs";

import { generateRandomId } from "../utils/helperFunctions";

export default function CustomTable(props) {
  const { headers = [], rows = [], colCanSort = [] } = props;
  const [sortByCol, setSortByCol] = useState(-1);
  const [sortByDir, setSortByDir] = useState(1);
  return (
    <Table responsive borderless striped hover size="sm" className="song_table">
      <thead>
        <tr>
          {headers.map(({ label }, index) => {
            const isCurrentSortingCol = index === sortByCol;
            return (
              <th
                key={generateRandomId()}
                style={{ cursor: colCanSort[index] ? "pointer" : undefined }}
                onClick={() => {
                  if (isCurrentSortingCol) {
                    setSortByDir(sortByDir * -1);
                  } else {
                    if (colCanSort[index]) setSortByCol(index);
                  }
                }}
              >
                {label}
                {colCanSort[index] &&
                  isCurrentSortingCol &&
                  (sortByDir === 1 ? <BsSortAlphaUpAlt /> : <BsSortAlphaDownAlt />)}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {rows
          .sort((x, y) =>
            colCanSort[sortByCol] ? sortByDir * x[sortByCol].value.localeCompare(y[sortByCol].value, "zh-CN") : 1
          )
          .map((row = []) => {
            return (
              <tr key={generateRandomId()}>
                {row.map((col) => {
                  const { onClick, value = "" } = col;
                  return (
                    <td
                      key={generateRandomId()}
                      onClick={onClick ? onClick : undefined}
                      style={{ cursor: onClick ? "pointer" : undefined }}
                    >
                      {value}
                    </td>
                  );
                })}
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
}
