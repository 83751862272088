import { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AppContext from "../context/app-context";

export default function Login() {
  const [searchParams, setSearchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");
  const { userProfile, setAppRefresh } = useContext(AppContext);
  const navigate = useNavigate();
  console.log(redirect);
  useEffect(() => {
    setAppRefresh(Math.random());
    navigate(redirect);
  }, []);
}
