import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./homePageSrc/Styles/LegalDocs.css";
const PrivacyPolicy = () => {
  return (
    <div className="legal-text-content">
      <h1 className="legal-siteTitle">Privacy Policy</h1>
      <p className="legal-description">
        This Privacy Policy (the “Policy”) is an integral part of your experience with Customer Link Hub, and we encourage
        you to read it thoroughly alongside our Terms of Service. We are committed to protecting the privacy of our users and
        the information they entrust to us.
      </p>

      <p className="legal-title">Information We Collect About You</p>
      <p className="legal-description">
        <strong>General Information:</strong> We may collect personal information that identifies you, such as your name,
        email address, and other non-identifying information. This may be collected when you use our app and may be stored on
        servers located worldwide.
      </p>
      <p className="legal-description">
        <strong>Information You Provide:</strong> We may collect and store any personal information you enter or upload
        within the app, such as your name, email address, and contact details. Additionally, we may collect usage data and
        other relevant information to improve our services.
      </p>
      <p className="legal-description">
        <strong>Information Collected Automatically:</strong> When you use Customer Link Hub, we automatically collect
        information from your device and browser, including IP address, browser type, access times, and cookie data.
      </p>

      <p className="legal-title">How We Use Your Information</p>
      <ul>
        <li>Provide and improve our services.</li>
        <li>Manage your account and provide customer support.</li>
        <li>Conduct research and analysis to enhance user experience.</li>
        <li>Communicate with you about relevant products or services.</li>
        <li>Develop personalized content and advertising.</li>
        <li>Enforce our terms of service and manage our business operations.</li>
      </ul>

      <p className="legal-title">Sharing Your Information</p>
      <p className="legal-description">We may share your personal information with:</p>
      <ul>
        <li>Authorized service providers who assist in delivering our services.</li>
        <li>Business partners for collaborative purposes.</li>
        <li>Third parties in response to legal requests or to protect our rights.</li>
      </ul>

      <p className="legal-title">Your Choices</p>
      <p className="legal-description">
        You have the option to opt out of receiving promotional communications from us and access the personal information we
        hold about you.
      </p>

      <p className="legal-title">Data Security</p>
      <p className="legal-description">
        We take appropriate security measures to protect your personal information; however, we cannot guarantee absolute
        security.
      </p>

      <p className="legal-title">Data Retention</p>
      <p className="legal-description">
        Your account data is stored as long as your account is active. You have the option to delete your account or request
        data deletion.
      </p>

      <p className="legal-title">Changes to This Policy</p>
      <p className="legal-description">
        We may update this Policy periodically to reflect changes in our practices and services. We will notify you of any
        material changes to the Policy.
      </p>

      <p className="legal-title">GDPR Compliance</p>
      <p className="legal-description">We comply with GDPR regulations. For more information, please contact us.</p>

      <p className="legal-title">Contact Us</p>
      <p className="legal-description">
        If you have any questions or concerns about this Policy or our privacy practices, please contact us through the
        contact feature within the app or via email at <a href="mailto:contact@travel-llm.com">contact@travel-llm.com</a>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
