const BASIC_PLAN = "Basic Plan";
const ADVANCE_PLAN = "Advance Plan";
const ENTERPRISE_PLAN = "Enterprise Plan";
const FREE_TRAIL = "Free Trail";

const BILLING_PLANS_CONFIGS = {
  [FREE_TRAIL]: {
    maxQueueCount: 3,
    maxUserCount: 1,
    emailTemplateEnable: false,
    adHocCaseCreationEnable: true,
    followUpEmailEnable: false,
    storeActivityEnable: false,
    canViewCaseDetails: true,
  },
  [BASIC_PLAN]: {
    maxQueueCount: 3,
    maxUserCount: 1,
    emailTemplateEnable: false,
    adHocCaseCreationEnable: false,
    followUpEmailEnable: false,
    storeActivityEnable: false,
    canViewCaseDetails: true,
  },
  [ADVANCE_PLAN]: {
    maxQueueCount: 5,
    maxUserCount: 3,
    emailTemplateEnable: true,
    adHocCaseCreationEnable: true,
    followUpEmailEnable: false,
    storeActivityEnable: false,
    canViewCaseDetails: true,
  },
  [ENTERPRISE_PLAN]: {
    maxQueueCount: 10,
    maxUserCount: 10,
    emailTemplateEnable: true,
    adHocCaseCreationEnable: true,
    followUpEmailEnable: true,
    storeActivityEnable: true,
    canViewCaseDetails: true,
  },
};

export function getFeatureConfigByPlan(plan) {
  const {
    maxQueueCount,
    maxUserCount,
    emailTemplateEnable,
    adHocCaseCreationEnable,
    followUpEmailEnable,
    storeActivityEnable,
    canViewCaseDetails,
  } = BILLING_PLANS_CONFIGS[plan] || {};
  return {
    maxQueueCount,
    maxUserCount,
    emailTemplateEnable,
    adHocCaseCreationEnable,
    followUpEmailEnable,
    storeActivityEnable,
    canViewCaseDetails,
  };
}
