import React, { useEffect, useState } from "react";
import Doctor from "../Assets/teamwork.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "../Styles/Hero.css";

function Hero() {
  const navigate = useNavigate();
  const [goUp, setGoUp] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleBookAppointmentClick = () => {
    navigate("/appointment");
  };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.scrollY > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);

  return (
    <div className="section-container">
      <div className="hero-section">
        <div className="text-section">
          <p className="text-headline">❤️ Customer Link Hub</p>
          <h2 className="text-title">🌟 Elevate Your Customer Service Game with Our App! 🌟</h2>
          <p className="text-descritpion">
            Welcome to a world where customer service is not just a task but an art form! Say goodbye to the hassle of manual
            order management. Our Customer Service App revolutionizes the way you engage with your customers, ensuring every
            interaction is personalized, efficient, and delightful.
          </p>
          <a target="_blank" href="https://apps.shopify.com/customer-link-hub">
            <button className="text-appointment-btn" type="button">
              <FontAwesomeIcon icon={faCalendarCheck} /> Install Our App On Shopify Now
            </button>{" "}
          </a>
          {/* <div className="text-stats">
            <div className="text-stats-container">
              <p>145k+</p>
              <p>Receive Patients</p>
            </div>

            <div className="text-stats-container">
              <p>50+</p>
              <p>Expert Doctors</p>
            </div>

            <div className="text-stats-container">
              <p>10+</p>
              <p>Years of Experience</p>
            </div>
          </div> */}
        </div>

        <div className="hero-image-section">
          <img className="hero-image1" src={Doctor} alt="Doctor" />
        </div>
      </div>

      <div onClick={scrollToTop} className={`scroll-up ${goUp ? "show-scroll" : ""}`}>
        <FontAwesomeIcon icon={faAngleUp} />
      </div>
    </div>
  );
}

export default Hero;
