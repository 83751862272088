import { INPUT_TYPE } from "../constants";

export const NEW_USER_CONFIGS = [
  {
    fieldName: "firstName",
    label: "First Name",
    inputType: INPUT_TYPE.TEXT,
    required: true,
  },
  {
    fieldName: "middleName",
    label: "Middle Name",
    inputType: INPUT_TYPE.TEXT,
  },
  {
    fieldName: "lastName",
    label: "Last Name",
    inputType: INPUT_TYPE.TEXT,
    required: true,
  },
  {
    fieldName: "emailAddress",
    label: "Email Address",
    inputType: INPUT_TYPE.EMAIL,
    required: true,
  },
  {
    fieldName: "employmentType",
    label: "Employment Type",
    inputType: INPUT_TYPE.SELECT,
    enumValues: [{ name: "MANAGER" }, { name: "IC" }],
    required: true,
  },
  {
    fieldName: "jobLevel",
    label: "Job Level",
    inputType: INPUT_TYPE.NUMBER,
    required: true,
  },
  {
    fieldName: "jobTitle",
    label: "Job Title",
    inputType: INPUT_TYPE.TEXT,
    required: true,
  },
  {
    fieldName: "initialVacation",
    label: "Starting Vacation Hours",
    inputType: INPUT_TYPE.NUMBER,
    required: true,
  },
  {
    fieldName: "initialPersonalTime",
    label: "Starting Personal Hours",
    inputType: INPUT_TYPE.NUMBER,
    required: true,
  },
  {
    fieldName: "lastHiredDate",
    label: "Last Hired Date",
    inputType: INPUT_TYPE.DATE,
    required: true,
  },
];

export const UPDATE_POSITION_CONFIGS = [
  {
    fieldName: "employmentType",
    label: "Employment Type",
    inputType: INPUT_TYPE.SELECT,
    enumValues: [{ name: "MANAGER" }, { name: "IC" }],
    required: true,
  },
  {
    fieldName: "jobLevel",
    label: "Job Level",
    inputType: INPUT_TYPE.NUMBER,
    required: true,
  },
  {
    fieldName: "jobTitle",
    label: "Job Title",
    inputType: INPUT_TYPE.TEXT,
    required: true,
  },
  {
    fieldName: "lastHiredDate",
    label: "Last Hired Date",
    inputType: INPUT_TYPE.DATE,
    required: true,
  },
  // {
  //   fieldName: "employmentStatus",
  //   label: "Employment Status",
  //   inputType: INPUT_TYPE.SELECT,
  //   enumValues: [{ name: "ACTIVE" }, { name: "INACTIVE" }],
  //   required: true,
  // },
];

export function getInputTypeWithManager(config = [], teamMembers) {
  return [
    ...config,
    {
      fieldName: "manager",
      label: "manager",
      required: true,
      inputType: INPUT_TYPE.SELECT,
      enumValues: teamMembers
        .filter((item) => item.employmentType === `MANAGER`)
        .map((item) => ({
          ...item,
          name: item.fullName,
        })),
    },
  ];
}
