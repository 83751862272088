import React from "react";
import Doctor from "../Assets/aboutus.png";
import SolutionStep from "./SolutionStep";
import "../Styles/About.css";

function About() {
  return (
    <div className="about-section" id="about">
      <div className="about-image-content">
        <img src={Doctor} alt="Doctor Group" className="about-image1" />
      </div>

      <div className="about-text-content">
        <h3 className="about-title">
          <span>🌟About Us</span>
        </h3>
        <p className="about-description">
          Welcome to Customer Link Hub, your partner in revolutionizing customer service excellence! At Customer Link Hub,
          we're passionate about empowering businesses to deliver exceptional customer experiences. Founded with the vision
          of simplifying customer service operations, our journey began with a simple goal: to provide merchants with the
          tools they need to streamline communication, enhance productivity, and foster long-lasting customer relationships.
        </p>

        <h4 className="about-text-title">
          Guided by Innovation and Customer-Centric Values, Customer Link Hub Leads in Service Solutions
        </h4>

        <SolutionStep
          title="🤝 Customer-Centric Approach"
          description="We prioritize the needs of our users above all else, striving to exceed expectations and deliver tangible value with every interaction."
        />

        <SolutionStep
          title="🚀 Continuous Innovation"
          description="We're relentless in our pursuit of excellence, constantly innovating and refining our products to stay ahead of the curve and meet the evolving needs of our customers."
        />

        <SolutionStep
          title="🌐 Empowering Collaboration"
          description="We believe in the power of collaboration and teamwork, fostering an environment where creativity thrives, and diverse perspectives are celebrated."
        />
        <SolutionStep
          title="🔒 Commitment to Quality"
          description="We hold ourselves to the highest standards of quality and reliability, ensuring that our products are not only effective but also trustworthy and dependable."
        />
        <SolutionStep
          title="💡 Transparency and Integrity"
          description="We conduct ourselves with honesty, transparency, and integrity in all aspects of our business, earning the trust and loyalty of our customers and partners."
        />
      </div>
    </div>
  );
}

export default About;
